"use client"
import { useId } from 'react';
import Image from 'next/image';
import { Tab } from '@headlessui/react';
import Link from 'next/link';
import clsx from 'clsx';
import { ContainerSection } from './ContainerSection';

import aiInstagramGrowthImage from '@public/img/graphics/instagram-growth-web-dashboard.png';
import safeSecureImage from '@public/img/graphics/safe-instagram-followers.png';
import liveAnalyticsImage from '@public/img/graphics/upgrow-instagram-analytics.png';
// import liveDashboardImage from '@public/img/graphics/ai-instagram-growth.png';

const features = [
  {
    name: 'AI-Powered Growth',
    // summary: 'Stay on top of things with always up-to-date reporting features.',
    description: "It's 2025 – try our innovative & proven Instagram growth approach.",
    image: aiInstagramGrowthImage,
    iconGradient: 'bg-gradient-to-t from-indigo-600 to-purple-600',
    icon: function ReportingIcon() {
      let id = useId();
      return (
        <>
          <defs>
            <linearGradient id={id} x1="11.5" y1={18} x2={36} y2="15.5" gradientUnits="userSpaceOnUse">
              <stop offset=".194" stopColor="#fff" />
              <stop offset={1} stopColor="#6692F1" />
            </linearGradient>
          </defs>
          <path d="m30 15-4 5-4-11-4 18-4-11-4 7-4-5" stroke={`url(#${id})`} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        </>
      );
    },
  },
  {
    name: 'Live Analytics',
    description: 'Track your growth in real-time with our award-winning dashboard.',
    image: liveAnalyticsImage,
    iconGradient: 'bg-gradient-to-t from-red-500 to-orange-500',
    icon: function InventoryIcon() {
      return (
        <>
          <path opacity=".5" d="M8 17a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-2Z" fill="#fff" />
          <path opacity=".3" d="M8 24a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-2Z" fill="#fff" />
          <path d="M8 10a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-2Z" fill="#fff" />
        </>
      );
    },
  },
  {
    name: 'Safe & Secure',
    description:
      'Experience worry-free Instagram-compliant growth without any risk.',
    image: safeSecureImage,
    iconGradient: 'bg-gradient-to-t from-green-600 to-emerald-600',
    icon: function ContactsIcon() {
      return (
        <>
          <path opacity=".5" d="M25.778 25.778c.39.39 1.027.393 1.384-.028A11.952 11.952 0 0 0 30 18c0-6.627-5.373-12-12-12S6 11.373 6 18c0 2.954 1.067 5.659 2.838 7.75.357.421.993.419 1.384.028.39-.39.386-1.02.036-1.448A9.959 9.959 0 0 1 8 18c0-5.523 4.477-10 10-10s10 4.477 10 10a9.959 9.959 0 0 1-2.258 6.33c-.35.427-.354 1.058.036 1.448Z" fill="#fff" />
          <path d="M12 28.395V28a6 6 0 0 1 12 0v.395A11.945 11.945 0 0 1 18 30c-2.186 0-4.235-.584-6-1.605ZM21 16.5c0-1.933-.5-3.5-3-3.5s-3 1.567-3 3.5 1.343 3.5 3 3.5 3-1.567 3-3.5Z" fill="#fff" />
        </>
      );
    },
  },
];

export function Feature({ feature, isActive, className, ...props }) {
  return (
    <div className={clsx(className,
      !isActive && 'opacity-[0.65] hover:opacity-100',
      'landing-card-wrapper transition-opacity duration-300'
    )}
    >
      <div className={clsx(className, 'landing-card')} {...props}>
        <div className="inline-flex">
          <div className={clsx('h-9 w-9 rounded-lg', isActive ? feature.iconGradient : 'bg-slate-500')}>
            <svg aria-hidden="true" className="h-9 w-9" fill="none">
              <feature.icon />
            </svg>
          </div>
          <h3 className={clsx('my-auto ml-4 pt-[3px] text-xl font-semibold', isActive ? 'text-gray-900' : 'text-slate-600')}>
            {feature.name}
          </h3>
        </div>
        {/* <p className="mt-2 text-2xl font-dashboard text-slate-900">
          {feature.summary}
        </p> */}
        <p className="mt-3 text-md text-slate-600">{feature.description}</p>
      </div>
    </div>
  );
}

function FeaturesMobile() {
  return (
    <div className="flex flex-col px-4 mt-20 -mx-4 overflow-hidden gap-y-10 sm:-mx-6 sm:px-6 lg:hidden">
      {features.map((feature) => (
        <div key={feature.name}>
          <Feature feature={feature} className="max-w-2xl mx-auto" isActive />
          <div className="relative pb-10 mt-10">
            <div className="absolute bottom-0 -inset-x-4 top-8 bg-slate-200 sm:-inset-x-6" />
            <div className="relative mx-auto w-auto sm:w-[52.75rem] overflow-hidden rounded-xl bg-white shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10">
              <Image
                className="w-full"
                src={feature.image}
                alt={feature.description}
                sizes="52.75rem"
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

function FeaturesDesktop() {
  return (
    <Tab.Group as="div" className="hidden lg:mt-20 lg:block" defaultIndex={0}>
      {({ selectedIndex }) => (
        <>
          <Tab.List className="grid grid-cols-3 gap-x-8" aria-roledescription="features-tabs">
            {features.map((feature, featureIndex) => (
              <Feature
                key={feature.name}
                feature={{
                  ...feature,
                  name: (
                    <Tab className="outline-0 [&:not(:focus-visible)]:focus:outline-none">
                      <span className="absolute inset-0" />
                      {feature.name}
                    </Tab>
                  ),
                }}
                isActive={featureIndex === selectedIndex}
                className="relative"
              />
            ))}
          </Tab.List>
          <Tab.Panels className="relative mt-12 overflow-hidden rounded-[31px] bg-gradient-to-r px-14 py-16 shadow-[0_10px_20px_0_rgba(0,0,0,0.1)] transition-all duration-300 xl:px-16">
            <div
              className={clsx(
                'absolute inset-0 h-full w-full bg-gradient-to-r from-[#13ad45] to-[#6ecb3e] opacity-0 transition duration-500 hover:opacity-100',
                selectedIndex === 0 && 'opacity-100'
              )}
            />
            <div
              className={clsx(
                'absolute inset-0 h-full w-full bg-gradient-to-r from-[#ffbb00] to-[#fd7702] opacity-0 transition duration-500 hover:opacity-100',
                selectedIndex === 1 && 'opacity-100'
              )}
            />
            <div
              className={clsx(
                'absolute inset-0 h-full w-full bg-gradient-to-r from-[#a49ff7] to-[#768ff7] opacity-0 transition duration-500 hover:opacity-100',
                selectedIndex === 2 && 'opacity-100'
              )}
            />

            <div className="flex -mx-5">
              {features.map((feature, featureIndex) => (
                <Tab.Panel
                  static
                  key={feature.name}
                  className={clsx(
                    'px-5 transition duration-500 ease-in-out [&:not(:focus-visible)]:focus:outline-none',
                    featureIndex !== selectedIndex && 'opacity-60'
                  )}
                  style={{ transform: `translateX(-${selectedIndex * 100}%)` }}
                  aria-hidden={featureIndex !== selectedIndex}
                >
                  <div className="w-[52.75rem] overflow-hidden rounded-xl bg-white shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10">
                    <Image
                      className="w-full"
                      src={feature.image}
                      alt={feature.description}
                      sizes="52.75rem"
                    />
                  </div>
                </Tab.Panel>
              ))}
            </div>
            <div className="pointer-events-none absolute inset-0 rounded-[31px] ring-1 ring-inset ring-slate-900/10" />
          </Tab.Panels>
        </>
      )}
    </Tab.Group>
  );
}

export default function LandingFeatures() {
  return (
    <ContainerSection
      id="features"
      aria-label="Features for powerful Instagram growth"
      className={undefined}
    >
      <div className="px-3 py-12 section-gradient-1 sm:py-24 lg:px-8">
        <div className="max-w-3xl mx-auto text-center">
          <span className="badge-gradient-1 mb-5 inline-flex rounded-full px-4 py-2 uppercase leading-6 tracking-[0.5px] text-indigo-600">
            <span className="text-sm font-extrabold text-transparent text-gradient-1 bg-clip-text">
              Award-Winning Growth
            </span>
          </span>
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 md:text-5xl">
            The #1 Rated Instagram
            <br /> Growth Service
          </h2>
          <p className="mt-6 text-lg leading-7 text-gray-600 lg:leading-8 lg:text-xl/8">
            Since 2016, UpGrow has helped 100,000+ Instagram users get real
            followers
            <br /> 10x faster. Say goodbye to bots and spam, and hello to safe,
            reliable growth.
            <br />
            <br /> We've built{' '}
            <Link href="#" className="underline">
              our proprietary AI-powered growth engine
            </Link>{' '}
            over 5 years of meticulous
            <br /> research, to provide a safe, spam-free way to build your
            Instagram following.
          </p>
        </div>

        <FeaturesMobile />
        <FeaturesDesktop />
      </div>
    </ContainerSection>
  );
}
