"use client"
import React, { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { useInView } from 'framer-motion';
import Anchor from '../Anchor/Anchor';
import LandingVideo from './LandingVideo';
import Image from 'next/image';
import { classNames } from '@/lib/utils';
import LandingAwardBadges from '../presentational/tailwind/LandingAwardBadges';

export const reviews = [
  {
    title: 'It really works.',
    rating: 5,
    source: 'twitter-gold',
    body: "I've tried other Instagram growth services in the past, but UpGrow is by far the best. They really deliver on their promise of real, organic growth, and I've seen a steady increase in my followers since signing up. Highly recommend!",
    author: 'Dreamize SMA',
    authorAvatar: '/img/reviews/dreamize-sma.png'
  },
  {
    title: 'You need this in your Instagram toolkit.',
    rating: 5,
    source: 'twitter-gold',
    body: "Thanks to UpGrow, I've been able to grow my Instagram following by over 50% in just a few months. The results speak for themselves - UpGrow is the best investment for an online business.",
    author: 'Leonardo Di Sante',
    authorAvatar: '/img/reviews/leonardo-di-sante.png'
  },
  {
    title: 'This shouldn’t be legal.',
    rating: 5,
    source: 'twitter',
    body: "As a fitness influencer, growing my Instagram page has always been a top priority. UpGrow has helped me take my page to the next level - in just one month, I gained over 4,000+ real followers. It's been a game-changer for my brand, and I couldn't be happier with the results.",
    author: 'Manuel Waterman',
    authorAvatar: '/img/avatars/238.jpg'
  },
  {
    title: 'Our social media manager swears by it.',
    rating: 5,
    source: 'twitter-gold',
    body: "UpGrow is the secret to growing your Instagram following fast! In just one month, I gained over 4,000 real followers - it's been a total game-changer for my fitness account.",
    author: 'Oliver1962',
    avatarColor: 'bg-blue-200',
    avatarTextColor: 'text-blue-500'
  },
  {
    title: 'The best Instagram growth service out there.',
    rating: 5,
    source: 'twitter-gold',
    body: "If you're a foodie looking to grow your Instagram following, UpGrow is a must-have tool. Thanks to their innovative technology, I have doubled my fan base in just a few weeks. It's been a great way to connect with fellow food lovers and expand my reach!",
    author: 'Gigi O.',
    authorAvatar: '/img/reviews/gigi.png'
  },
  {
    title: 'Too good to be true.',
    rating: 5,
    source: 'twitter-gold',
    body: "UpGrow has helped me take my e-commerce brand to the next level. In just 3 months, we've gained over 20,000+ new followers - all potential customers! It's been an amazing way to grow my brand and increase sales.",
    author: 'Jennifer Khan',
    authorAvatar: '/img/reviews/jennifer-khan.png'
  },
  {
    title: 'Wish I could give 6 stars',
    rating: 5,
    source: 'twitter-gold',
    body: "As a musician, growing my Instagram following has always been a challenge. But thanks to UpGrow, it's become a lot easier. In just a few months, I gained over 10k real followers, and it's been an amazing way to promote my music and connect with fans on the platform. I highly recommend UpGrow to any musician looking to grow their Instagram presence.",
    author: 'Ivy Jennifer Granada',
    authorAvatar: '/img/reviews/ivy-jennifer-granada.png'
  },
  {
    title: "AI is the future of Instagram growth. Period.",
    rating: 5,
    source: 'trustpilot',
    body: "I own a small restaurant, and I've been struggling to gain a foothold on Instagram. UpGrow is a no-brainer for any restaurant owner looking to get more people in.",
    author: 'Artsouljboy',
    avatarColor: 'bg-pink-200',
    avatarTextColor: 'text-pink-500'
  },
  {
    title: "I've 5x my income from Instagram.",
    body: "UpGrow has been a huge help in growing my Instagram following as a webcam model. Thanks to them, I gained real followers quickly, which has increased my reach and income. It's been an amazing way to connect with fans and promote my content",
    rating: 5,
    source: 'trustpilot',
    author: 'Jenny Gelshtein',
    avatarColor: 'bg-orange-200',
    avatarTextColor: 'text-orange-500'
  },
  {
    title: "My followers have doubled!",
    rating: 5,
    source: 'twitter-gold',
    body: "I've been using UpGrow for a few months now and it's been a great tool for growing my Instagram following as an artist. Thanks to their unique approach to Instagram growth, I gained real, engaged followers who are genuinely interested in my art. It's been a great way to showcase my work and connect with new fans and collectors. I swear by UpGrow to any artist looking to expand their reach.",
    author: 'Donald B.',
    authorAvatar: '/img/reviews/donald-b.png'
  },
  {
    title: 'UpGrow is such a good deal!',
    rating: 5,
    source: 'trustpilot',
    body: 'I was skeptical at first, but UpGrow has been a game-changer for my Instagram account. I gained over 10k real followers in just a few months, and it’s been a great way to connect with new fans and promote my music. I highly recommend UpGrow to any musician looking to grow their Instagram presence.',
    author: 'VSG Entertainment',
    authorAvatar: '/img/reviews/vsg-entertainment.png'
  },
  {
    title: 'It’s like a superpower.',
    rating: 5,
    source: 'trustpilot',
    body: "UpGrow feels like a social media superpower. I've been able to gain over 4k real Instagram followers in just a few weeks! One less thing to worry about.",
    author: 'Manuri Clothing',
    authorAvatar: '/img/reviews/manuri-clothing.png'
  },
];

export default function ReviewsSection({ isReviewsPage = false, className = '' }: { isReviewsPage?: boolean; className?: string }) {
  return (
    <div className={classNames("z-1 relative max-w-full bg-[#fafafa] pb-2 sm:pb-4",
      !isReviewsPage && 'mt-[-150px] pt-20 sm:pt-40',
      className
    )}
      id="reviews"
      aria-labelledby="reviews-title"
    >
      <div className={classNames("max-w-6xl px-6 pb-5 mx-auto lg:px-8",
        isReviewsPage ? 'pt-4 sm:pt-0' : 'pt-20'
      )}>
        <div className="max-w-2xl mx-auto text-center">
          <span className="badge-gradient-1 mb-5 inline-flex rounded-full px-4 py-2 uppercase leading-6 tracking-[0.5px] text-indigo-600">
            <strong className="text-sm font-extrabold text-transparent text-gradient-1 bg-clip-text">
              Real Reviews
            </strong>
          </span>
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 md:text-5xl">
            Trusted by{' '}
            <span className="text-transparent text-gradient-1 bg-clip-text">
              55,000+
            </span>{' '}
            customers all over the world
          </h2>
          <p className="mt-6 text-lg leading-7 text-gray-600 lg:text-xl lg:leading-8">
            Don't just take our word for it - see what our satisfied customers
            have to say about their Instagram glow up!
          </p>
        </div>
        <ReviewGrid />

        <div className="justify-center block pt-10 pb-2 text-center">
          {!isReviewsPage
            ? (
              <>
                <Anchor href="/reviews" className="box-border inline-flex items-center justify-center px-5 pb-px font-semibold text-gray-700 transition-all duration-200 bg-white border border-gray-200 shadow-xs hover:bg-gray-50 hover:text-gray-900 rounded-xl text-md border-1 h-11 whitespace-nowrap">
                  See all customer results
                </Anchor>
                <LandingAwardBadges className="w-full pt-16 pb-2 max-5xl" />
              </>
            ) : (
              <>
                <LandingAwardBadges className="w-full pt-20 pb-5" />
                <LandingVideo isReviewsPage={true} />
              </>
            )
          }
        </div>
      </div>
    </div>
  );
}


const StarIcon = React.memo(function StarIcon(props) {
  return (
    <svg viewBox="0 0 20 20" aria-hidden="true" {...props}>
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
    </svg>
  );
});

export function StarRating({ rating }) {
  return (
    <div className="flex">
      {Array.from(Array(5).keys()).map((index) => (
        <StarIcon key={index} {...{
          className: clsx('h-5 w-5',
            rating > index ? 'fill-yellow-300' : 'fill-gray-300'
          )
        }} />
      ))}
    </div>
  );
}

export const TrustpilotStarsIcon = React.memo(function TrustpilotStarsIcon() {
  return (<Image src="/img/icons/trustpilot-icon.webp" className="w-auto h-4" alt="Trustpilot Review Icon" width={85} height={16} loading="lazy" />);
});

export const TwitterIcon = React.memo(function TwitterIcon() {
  return (<Image src="/img/icons/verified-twitter-icon-blue.webp" className="w-4 h-4" alt="Verified Twitter Blue Badge" width={18} height={18} loading="lazy" />);
});

export const TwitterGoldIcon = React.memo(function TwitterGoldIcon() {
  return (<Image src="/img/icons/verified-twitter-icon-gold.webp" className="w-4 h-4" alt="Verified Twitter Review Badge" width={18} height={18} loading="lazy" />);
});

export const TrustpilotLogo = React.memo(function TrustpilotLogo() {
  return (<Image src="/img/icons/trustpilot-logo.webp" className="w-5 h-5" alt="Truspilot Logo" width={20} height={20} loading="lazy" />);
});


function Review({ title, body, author, rating, className, authorAvatar, avatarColor, avatarTextColor, ...domProps }) {
  let animationDelay = useMemo(() => {
    let possibleAnimationDelays = [
      '0s',
      '0.1s',
      '0.2s',
      '0.3s',
      '0.4s',
      '0.5s',
    ];
    return possibleAnimationDelays[
      Math.floor(Math.random() * possibleAnimationDelays.length)
    ];
  }, []);

  return (
    <figure
      className={clsx(
        'animate-fade-in rounded-3xl bg-white p-6 opacity-0 shadow-md shadow-gray-900/5',
        className
      )}
      style={{ animationDelay }}
      {...domProps}
    >
      <blockquote className="text-gray-900">
        {domProps.source === 'trustpilot'
          ? <TrustpilotStarsIcon />
          : <StarRating rating={rating} />
        }
        <p className="mt-4 text-lg font-semibold leading-6 before:content-['“'] after:content-['”']">
          {title}
        </p>
        <p className="mt-3 text-base leading-7">{body}</p>
      </blockquote>
      <div className="flex items-center mt-3 text-base font-semibold text-gray-700">
        {authorAvatar
          ? (<Image
            src={authorAvatar}
            alt={`Avatar of ${author}`}
            width={40}
            height={40}
            className="w-10 h-10 mr-3 bg-gray-300 rounded-full"
            loading="lazy"
          />)
          : (
            <div className={classNames("flex items-center justify-center w-10 h-10 mr-3 rounded-full",
              avatarColor,
              avatarTextColor || ''
            )}>
              <span className="text-sm font-semibold">{author.split(' ').map(name => name[0]).join('')}</span>
            </div>
          )}

        <span className='mr-1.5'>{author}</span>

        {domProps.source === 'trustpilot'
          ? <TrustpilotLogo />
          : domProps.source === 'twitter'
            ? <TwitterIcon />
            : domProps.source === 'twitter-gold'
              ? <TwitterGoldIcon />
              : <TwitterIcon />
        }
      </div>
    </figure>
  );
}

function splitArray(array, numParts) {
  let result = [];
  for (let i = 0; i < array.length; i++) {
    let index = i % numParts;
    if (!result[index]) {
      result[index] = [];
    }
    result[index].push(array[i]);
  }
  return result;
}

function ReviewColumn({ className, reviews, reviewClassName = (any) => { }, msPerPixel = 0 }) {
  let columnRef = useRef<HTMLInputElement>();
  let [columnHeight, setColumnHeight] = useState(0);
  let duration = `${columnHeight * msPerPixel}ms`;

  useEffect(() => {
    let resizeObserver = new window.ResizeObserver(() => {
      if (columnRef && columnRef.current)
        setColumnHeight(columnRef.current.offsetHeight);
    });
    resizeObserver.observe(columnRef.current);
    return () => { resizeObserver.disconnect() }
  }, []);

  return (
    <div
      ref={columnRef}
      className={clsx('animate-marquee space-y-8 py-4', className)}
      style={{ '--marquee-duration': duration } as React.CSSProperties}
    >
      {reviews.concat(reviews).map((review, reviewIndex) => (
        <Review
          key={reviewIndex}
          aria-hidden={reviewIndex >= reviews.length}
          className={reviewClassName(reviewIndex % reviews.length)}
          authorAvatar={review.authorAvatar}
          avatarColor={review.avatarColor}
          avatarTextColor={review.avatarTextColor}
          {...review}
        />
      ))}
    </div>
  );
}

function ReviewGrid() {
  let containerRef = useRef();
  let isInView = useInView(containerRef, { once: true, amount: 0.4 });
  let columns = splitArray(reviews, 3);
  columns = [columns[0], columns[1], splitArray(columns[2], 2)];

  return (
    <div
      ref={containerRef}
      className="section-gradient-1 relative -mx-4 mt-16 grid h-[49rem] max-h-[150vh] grid-cols-1 items-start gap-8 overflow-hidden px-4 sm:mt-20 md:grid-cols-2 lg:grid-cols-3"
    >
      {isInView && (
        <>
          <ReviewColumn
            reviews={[...columns[0], ...columns[2].flat(), ...columns[1]]}
            reviewClassName={(reviewIndex) =>
              clsx(
                reviewIndex >= columns[0].length + columns[2][0].length &&
                'md:hidden',
                reviewIndex >= columns[0].length && 'lg:hidden'
              )
            }
            msPerPixel={10}
            className={undefined}
          />
          <ReviewColumn
            reviews={[...columns[1], ...columns[2][1]]}
            className="hidden md:block"
            reviewClassName={(reviewIndex) =>
              reviewIndex >= columns[1].length && 'lg:hidden'
            }
            msPerPixel={15}
          />
          <ReviewColumn
            reviews={columns[2].flat()}
            className="hidden lg:block"
            msPerPixel={10}
          />
        </>
      )}
      <div className="absolute inset-x-0 top-0 h-32 pointer-events-none bg-gradient-to-b from-gray-50" />
      <div className="absolute inset-x-0 bottom-0 h-32 pointer-events-none bg-gradient-to-t from-gray-50" />
    </div>
  );
}